var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['base-sidebar',{ 'base-sidebar--show': _vm.sidebarShow }]},[_c('div',{staticClass:"base-sidebar__content"},[_c('div',{staticClass:"base-sidebar__inner"},[_c('section',{staticClass:"base-sidebar__head"},[_c('div',{staticClass:"base-sidebar__logo-box"},[_c('svg',{staticClass:"base-sidebar__logo-icon",attrs:{"width":"125","height":"24"}},[_c('use',{attrs:{"xlink:href":"#icon-logo"}})])]),_c('div',{staticClass:"base-sidebar__head-actions"},[_c('router-link',{staticClass:"base-sidebar__all-projects-link btn btn--primary",attrs:{"to":{ name: 'projects' }}},[_vm._v(" Все проекты ")]),_c('button',{staticClass:"base-sidebar__close-btn btn btn--transparent js--blur",attrs:{"type":"button","title":"Свернуть меню"},on:{"click":_vm.closeSidebar}},[_c('svg',{staticClass:"base-sidebar__close-btn-icon",attrs:{"width":"18","height":"16"}},[_c('use',{attrs:{"xlink:href":"#icon-arrow-double"}})])])],1)]),(_vm.showDataAboutProject)?_c('section',{staticClass:"base-sidebar__text-info"},[_c('div',{staticClass:"base-sidebar__text-info-general"},[_c('span',{staticClass:"base-sidebar__text-info-title"},[_vm._v(" "+_vm._s(_vm.projectDataForSidebar.projectName || _vm.projectDataForSidebar.title)+" ")]),(_vm.projectDescription && _vm.projectDescription.length)?_c('button',{class:[
                            'base-sidebar__text-info-spoiler-btn btn btn--transparent js--blur',
                            {'base-sidebar__text-info-spoiler-btn--rotated': _vm.descriptionOpen}
                        ],attrs:{"type":"button","title":((_vm.descriptionOpen ? 'Свернуть' : 'Развернуть') + " описание")},on:{"click":_vm.toggleDescription}},[_c('svg',{staticClass:"base-sidebar__text-info-spoiler-btn-icon",attrs:{"width":"10","height":"6"}},[_c('use',{attrs:{"xlink:href":"#icon-arrow"}})])]):_vm._e()]),(_vm.projectDescription && _vm.projectDescription.length)?_c('slide-up-down',{staticClass:"base-sidebar__text-info-spoiler",attrs:{"active":_vm.descriptionOpen,"duration":_vm.spoilerDuration}},[_c('p',{staticClass:"base-sidebar__text-info-description"},[_vm._v(" "+_vm._s(_vm.projectDescription)+" ")])]):_vm._e()],1):_vm._e(),(_vm.showDataAboutProject)?_c('section',{staticClass:"base-sidebar__nav"},[_c('ul',{staticClass:"base-sidebar__nav-list"},_vm._l((_vm.navLinks),function(item,index){return _c('li',{key:("sidebar-nav-link-" + (item.slug) + "-" + index),staticClass:"base-sidebar__nav-list-item"},[_c('router-link',{class:[
                                'base-sidebar__nav-list-item-link',
                                { 'base-sidebar__nav-list-item-link--loading': !item.isPageDataLoaded }
                            ],attrs:{"to":_vm.getRouteLink(item),"title":item.title}},[_c('span',{staticClass:"base-sidebar__nav-list-item-title"},[_vm._v(" "+_vm._s(item.title)+" "),(item.slug === 'documents' && _vm.agreementProcesses && _vm.agreementProcesses.length)?_c('span',{staticClass:"base-sidebar__attention-circle",attrs:{"title":"Имеются комплекты на согласовании"}}):_vm._e()]),(_vm.getCount(item, 'new'))?_c('span',{class:[
                                    'base-sidebar__nav-list-item-count',
                                    'base-sidebar__nav-list-item-count--new'
                                ]},[_vm._v(" +"+_vm._s(_vm.getCount(item, 'new'))+" ")]):_vm._e(),_c('span',{class:[
                                    'base-sidebar__nav-list-item-count',
                                    'base-sidebar__nav-list-item-count--total'
                                ]},[_vm._v(" "+_vm._s(_vm.getCount(item, 'all'))+" ")])])],1)}),0)]):_vm._e(),_c('section',{staticClass:"base-sidebar__favourites"},[_c('p',{staticClass:"base-sidebar__favourites-title"},[_vm._v(" Избранное ")]),_c('ul',{staticClass:"base-sidebar__favourites-list"},_vm._l((_vm.userFavorites),function(item,index){return _c('li',{key:("sidebar-favourite-" + index + "-" + (item.title)),staticClass:"base-sidebar__favourites-list-item"},[_c('a',{staticClass:"base-sidebar__favourites-list-item-link",attrs:{"href":item.url,"title":item.title}},[_c('span',{staticClass:"base-sidebar__favourites-list-item-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('svg',{staticClass:"base-sidebar__favourites-list-item-icon",attrs:{"width":"12","height":"11"}},[_c('use',{attrs:{"xlink:href":"#icon-star"}})])])])}),0)]),_c('BaseSidebarQrCodes')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }