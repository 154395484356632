





























































































































































import './scss/BaseSidebar.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IProjectTasksState } from '@store/modules/project-tasks/interfaces/Interfaces';
import { ProjectTasksMutations } from '@store/modules/project-tasks/Types';
import { ProjectTeamMutations } from '@store/modules/project-team/Types';
import { IDocument, IDocumentsState } from '@store/modules/project-documents/Interfaces';
import { ProjectDocumentsMutations } from '@store/modules/project-documents/Types';
import { IWorkListState } from '@store/modules/work-list/interfaces/Interfaces';
import { WorkListMutations } from '@store/modules/work-list/Types';

import { IProject, IProjectTask } from '@store/modules/project/Interfaces';
import { IUserState } from '@store/modules/user/Interfaces';

import { namespace } from 'vuex-class';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSSidebar = namespace('storeSidebar');
const NSWorkList = namespace('storeWorkList');
const NSProjectTeam = namespace('storeProjectTeam');
const NSProjectTasks = namespace('storeProjectTasks');
const NSDocuments = namespace('storeProjectDocuments');

import { SidebarMutationTypes } from "@store/modules/sidebar/Types";

@Component({
    components: {
        SlideUpDown: () => import('vue-slide-up-down'),
        BaseSidebarQrCodes: () => import('@components/BaseSidebar/BaseSidebarQrCodes.vue'),
    }
})
export default class BaseSidebar extends Vue {
    @NSProjectTasks.State((state: IProjectTasksState) => state.projectTasksLoaded) projectTasksLoaded!: boolean;
    @NSProjectTasks.Getter('tasks') tasks!: any[];
    @NSProjectTasks.Mutation(ProjectTasksMutations.M_SET_PROJECT_TASKS_LOADED) setTasksLoaded!: (loadedState: boolean) => void;
    @NSProjectTeam.Getter('projectTeamLoaded') projectTeamLoaded!: boolean;
    @NSProjectTeam.Getter('projectTeam') projectTeam!: any[];
    @NSProjectTeam.Mutation(ProjectTeamMutations.M_SET_PROJECT_TEAM_LOADED) setTeamLoaded!: (loadedState: boolean) => void;
    @NSDocuments.Getter('allReadDocuments') allReadDocuments!: IDocument[];
    @NSDocuments.State((state: IDocumentsState) => state.projectDocumentsLoaded) projectDocumentsLoaded!: boolean;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_PROJECT_DOCUMENTS_LOADED) setDocsLoaded!: (loadedState: boolean) => void;
    @NSWorkList.State((state: IWorkListState) => state.workListLoaded) workListLoaded!: boolean;
    @NSWorkList.Mutation(WorkListMutations.M_SET_WORK_LIST_LOADED) setWorkListLoaded!: (loadedState: boolean) => void;
    @NSProject.Getter('projectData') projectDataForSidebar!: IProject;
    @NSUser.Getter('userData') userData!: IUserState;
    @NSSidebar.Getter('sidebarShow') sidebarShow!: boolean;
    @NSSidebar.Mutation(SidebarMutationTypes.M_SET_SIDEBAR_SHOW) setSidebarShow!: (payload: boolean) => void;
    @NSDocuments.Getter('agreementProcesses') agreementProcesses!: string[];

    spoilerDuration: number = 300;
    descriptionOpen: boolean = false;

    get navLinks() {
        return [
            {
                slug: 'tasks',
                title: 'Задачи',
                url: '/project-tasks',
                isPageDataLoaded: this.projectTasksLoaded,
            },{
                slug: 'team',
                title: 'Команда',
                url: '/project-team',
                isPageDataLoaded: this.projectTeamLoaded,
            },{
                slug: 'documents',
                title: 'Документы',
                url: '/documents',
                isPageDataLoaded: this.projectDocumentsLoaded,
            },{
                slug: 'aboutProject',
                title: 'О проекте',
                url: '/about',
                isPageDataLoaded: true,
            },{
                slug: 'worksList',
                title: 'Список работ',
                url: '/work-list',
                isPageDataLoaded: true,
            },{
                slug: 'enterControl',
                title: 'Входной контроль',
                url: '/input-control',
                isPageDataLoaded: true,
            },
        ]
    }

    get documentCount() {
        return this.allReadDocuments.length;
    }

    get teamCount() {
        return this.projectTeam?.length || 0;
    }

    get idsOfTasksNotCompleted() {
        return this.tasks.filter(item => item.state.name !== 'Выполнено').map(item => item.uuid);
    }

    get tasksCount() {
        if (this.idsOfTasksNotCompleted.length) {
            const output: IProjectTask[] = [];
            this.tasks.forEach(item => {
                if (this.idsOfTasksNotCompleted.includes(item.uuid)) {
                    output.push(item);
                }
            });
            return output.length || 0;
        }
        return 0;
    }

    get documentCountNew() {
        if (this.projectDataForSidebar.newDocuments) {
            return this.projectDataForSidebar.newDocuments.length;
        }
        return null;
    }

    get teamCountNew() {
        if (this.projectDataForSidebar.newTeam) {
            return this.projectDataForSidebar.newTeam.length;
        }
        return 0;
    }

    get tasksCountNew() {
        if (this.projectDataForSidebar.newTasks && this.tasks) {
            const newTasks: string[] = [];
            const tasksIds = this.tasks.map(item => item.uuid);
            this.projectDataForSidebar.newTasks.forEach((task) => {
                if (tasksIds.includes(task)) {
                    newTasks.push(task);
                }
            })
            return newTasks.length;
        }
        return 0;
    }

    get showDataAboutProject() {
        return this.$route.name !== 'all-projects' && this.projectDataForSidebar && this.projectDataForSidebar.id;
    }

    get userFavorites() {
        if (this.userData && this.userData.userInfo && this.userData.userInfo.favorites.length) {
            return this.userData.userInfo.favorites;
        }
    }

    get projectDescription() {
        if (this.projectDataForSidebar && this.projectDataForSidebar.description) {
            return this.projectDataForSidebar.description;
        }
    }

    @Watch('$route.path')
    onChangeRoutePath() {
        if (this.$route.name === 'projects') {
            this.closeSidebar();
        }
    }

    @Watch('$route.params.projectId')
    watchProjectId() {
        this.setTasksLoaded(false);
        this.setTeamLoaded(false);
        this.setDocsLoaded(false);
        this.setWorkListLoaded(false);
    }

    closeSidebar() {
        this.setSidebarShow(false);
    }

    toggleDescription() {
        this.descriptionOpen = !this.descriptionOpen;
    }

    getRouteLink(item) {
        return `/project/${this.projectDataForSidebar.id}${item.url}`
    }

    getCount(item, mode) {
        if (item && item.slug) {
            switch (item.slug) {
                case 'tasks':
                    return mode === 'new' ? this.tasksCountNew : this.tasksCount;
                    break;
                case 'team':
                    return mode === 'new' ? this.teamCountNew : this.teamCount;
                    break;
                case 'documents':
                    return mode === 'new' ? this.documentCountNew : this.documentCount;
                    break;
                default:
                    return mode === 'new' ? 0 : '';
            }
        }
    }
}
